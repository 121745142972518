import { IEnvelopeProps, IEnvelopes } from 'iApp';

const Envelopes = ({
  number,
  is_open,
  setEnvelopes,
  setActiveEnvelope,
}: IEnvelopeProps) => {
  return is_open ? (
    <div className='flex w-[25px] h-[25px] md:w-[50px] md:h-[50px] m-2 border border-slate-300 rounded rounded-md justify-center items-center'>
      {number}
    </div>
  ) : (
    <i
      className='fa-regular fa-envelope flex w-[25px] h-[25px] md:w-[50px] md:h-[50px] m-2 rounded rounded-md justify-center items-center md:text-[60px]'
      onClick={() => {
        setEnvelopes((prev: IEnvelopes) => ({
          ...prev,
          [number]: {
            ...prev[number],
            is_open: !prev[number].is_open,
          },
        }));
        setActiveEnvelope(number);
      }}
    />
  );
};

export default Envelopes;
