import { IOutputProps } from 'iApp';

const Output = ({ envelope, totalSaved }: IOutputProps) => {
  return (
    <div>
      {envelope > 0 ? (
        <div className='flex flex-col justify-center items-center'>
          <h1>Last Opened Envelope</h1>
          {envelope}
        </div>
      ) : null}
      <div className='flex flex-col justify-center items-center'>
        <h1>Total Saved</h1>
        <div>{totalSaved}</div>
      </div>
    </div>
  );
};

export default Output;
