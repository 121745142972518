import { ReactNode, useMemo } from 'react';
import { IEnvelopes } from 'iApp';
import { useState } from 'react';
import Envelopes from 'Envelope';
import Output from 'Output';

const App = () => {
  const [envelopes, setEnvelopes] = useState<IEnvelopes>({
    1: {
      user_id: 0,
      is_open: false,
    },
    2: {
      user_id: 0,
      is_open: false,
    },
    3: {
      user_id: 0,
      is_open: false,
    },
    4: {
      user_id: 0,
      is_open: false,
    },
    5: {
      user_id: 0,
      is_open: false,
    },
    6: {
      user_id: 0,
      is_open: false,
    },
    7: {
      user_id: 0,
      is_open: false,
    },
    8: {
      user_id: 0,
      is_open: false,
    },
    9: {
      user_id: 0,
      is_open: false,
    },
    10: {
      user_id: 0,
      is_open: false,
    },
    11: {
      user_id: 0,
      is_open: false,
    },
    12: {
      user_id: 0,
      is_open: false,
    },
    13: {
      user_id: 0,
      is_open: false,
    },
    14: {
      user_id: 0,
      is_open: false,
    },
    15: {
      user_id: 0,
      is_open: false,
    },
    16: {
      user_id: 0,
      is_open: false,
    },
    17: {
      user_id: 0,
      is_open: false,
    },
    18: {
      user_id: 0,
      is_open: false,
    },
    19: {
      user_id: 0,
      is_open: false,
    },
    20: {
      user_id: 0,
      is_open: false,
    },
    21: {
      user_id: 0,
      is_open: false,
    },
    22: {
      user_id: 0,
      is_open: false,
    },
    23: {
      user_id: 0,
      is_open: false,
    },
    24: {
      user_id: 0,
      is_open: false,
    },
    25: {
      user_id: 0,
      is_open: false,
    },
    26: {
      user_id: 0,
      is_open: false,
    },
    27: {
      user_id: 0,
      is_open: false,
    },
    28: {
      user_id: 0,
      is_open: false,
    },
    29: {
      user_id: 0,
      is_open: false,
    },
    30: {
      user_id: 0,
      is_open: false,
    },
    31: {
      user_id: 0,
      is_open: false,
    },
    32: {
      user_id: 0,
      is_open: false,
    },
    33: {
      user_id: 0,
      is_open: false,
    },
    34: {
      user_id: 0,
      is_open: false,
    },
    35: {
      user_id: 0,
      is_open: false,
    },
    36: {
      user_id: 0,
      is_open: false,
    },
    37: {
      user_id: 0,
      is_open: false,
    },
    38: {
      user_id: 0,
      is_open: false,
    },
    39: {
      user_id: 0,
      is_open: false,
    },
    40: {
      user_id: 0,
      is_open: false,
    },
    41: {
      user_id: 0,
      is_open: false,
    },
    42: {
      user_id: 0,
      is_open: false,
    },
    43: {
      user_id: 0,
      is_open: false,
    },
    44: {
      user_id: 0,
      is_open: false,
    },
    45: {
      user_id: 0,
      is_open: false,
    },
    46: {
      user_id: 0,
      is_open: false,
    },
    47: {
      user_id: 0,
      is_open: false,
    },
    48: {
      user_id: 0,
      is_open: false,
    },
    49: {
      user_id: 0,
      is_open: false,
    },
    50: {
      user_id: 0,
      is_open: false,
    },
    51: {
      user_id: 0,
      is_open: false,
    },
    52: {
      user_id: 0,
      is_open: false,
    },
    53: {
      user_id: 0,
      is_open: false,
    },
    54: {
      user_id: 0,
      is_open: false,
    },
    55: {
      user_id: 0,
      is_open: false,
    },
    56: {
      user_id: 0,
      is_open: false,
    },
    57: {
      user_id: 0,
      is_open: false,
    },
    58: {
      user_id: 0,
      is_open: false,
    },
    59: {
      user_id: 0,
      is_open: false,
    },
    60: {
      user_id: 0,
      is_open: false,
    },
    61: {
      user_id: 0,
      is_open: false,
    },
    62: {
      user_id: 0,
      is_open: false,
    },
    63: {
      user_id: 0,
      is_open: false,
    },
    64: {
      user_id: 0,
      is_open: false,
    },
    65: {
      user_id: 0,
      is_open: false,
    },
    66: {
      user_id: 0,
      is_open: false,
    },
    67: {
      user_id: 0,
      is_open: false,
    },
    68: {
      user_id: 0,
      is_open: false,
    },
    69: {
      user_id: 0,
      is_open: false,
    },
    70: {
      user_id: 0,
      is_open: false,
    },
    71: {
      user_id: 0,
      is_open: false,
    },
    72: {
      user_id: 0,
      is_open: false,
    },
    73: {
      user_id: 0,
      is_open: false,
    },
    74: {
      user_id: 0,
      is_open: false,
    },
    75: {
      user_id: 0,
      is_open: false,
    },
    76: {
      user_id: 0,
      is_open: false,
    },
    77: {
      user_id: 0,
      is_open: false,
    },
    78: {
      user_id: 0,
      is_open: false,
    },
    79: {
      user_id: 0,
      is_open: false,
    },
    80: {
      user_id: 0,
      is_open: false,
    },
    81: {
      user_id: 0,
      is_open: false,
    },
    82: {
      user_id: 0,
      is_open: false,
    },
    83: {
      user_id: 0,
      is_open: false,
    },
    84: {
      user_id: 0,
      is_open: false,
    },
    85: {
      user_id: 0,
      is_open: false,
    },
    86: {
      user_id: 0,
      is_open: false,
    },
    87: {
      user_id: 0,
      is_open: false,
    },
    88: {
      user_id: 0,
      is_open: false,
    },
    89: {
      user_id: 0,
      is_open: false,
    },
    90: {
      user_id: 0,
      is_open: false,
    },
    91: {
      user_id: 0,
      is_open: false,
    },
    92: {
      user_id: 0,
      is_open: false,
    },
    93: {
      user_id: 0,
      is_open: false,
    },
    94: {
      user_id: 0,
      is_open: false,
    },
    95: {
      user_id: 0,
      is_open: false,
    },
    96: {
      user_id: 0,
      is_open: false,
    },
    97: {
      user_id: 0,
      is_open: false,
    },
    98: {
      user_id: 0,
      is_open: false,
    },
    99: {
      user_id: 0,
      is_open: false,
    },
    100: {
      user_id: 0,
      is_open: false,
    },
  });

  const [activeEnvelope, setActiveEnvelope] = useState<number>(0);
  const shuffledArray: number[] = useMemo(
    () =>
      Object.keys(envelopes)
        .sort((a, b) => 0.5 - Math.random())
        .map((env) => Number(env)),
    []
  );

  const totalSaved: number =
    activeEnvelope > 0
      ? shuffledArray
          .filter((env) => envelopes[env].is_open)!
          .reduce((a, b) => a + b)
      : 0;

  const envelopesToDisplay: ReactNode[] = shuffledArray.map(
    (env): ReactNode => (
      <Envelopes
        key={env}
        number={env}
        setEnvelopes={setEnvelopes}
        setActiveEnvelope={setActiveEnvelope}
        is_open={envelopes[env].is_open}
      />
    )
  );

  return (
    <div className='flex flex-col w-full h-screen items-center'>
      <div className='flex flex-wrap w-5/6 h-5/6 md:h-2/3 m-3 p-5 border border-stone-500 rounded rounded-md justify-center items-center'>
        {envelopesToDisplay}
      </div>
      <div className='flex w-5/6 1/6 md:h-1/3 m-3 p-5 border border-stone-500 rounded rounded-md justify-center items-center'>
        <Output
          envelope={activeEnvelope}
          totalSaved={totalSaved}
        />
      </div>
    </div>
  );
};

export default App;
